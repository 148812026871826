@import './globalStyle.scss';
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

#editor * {
  font-family: monospace !important;
  font-size: 16px !important;
  direction: ltr !important;
  text-align: left !important;
}
