@import './../../globalStyle.scss';

.editor {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $secondary;
  &-console {
    width: 30% !important;
    background-color: $secondary;
    border-left: 1px solid whitesmoke;
    padding: 0.5rem !important;
    padding-top: 1rem !important;
    color: whitesmoke;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    &__textarea {
      width: 100%;
      height: 100%;
      background-color: $secondary;
      border: none;
      color: whitesmoke;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen;
      font-size: 14px;
      font-weight: 400;
      resize: none;
      outline: none;
    }
  }
  .editorcode {
    width: 70% !important;
    height: 93.1vh !important;
    margin-top: 1rem !important;
    background-color: $secondary;
    color: whitesmoke;
  }
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  padding: 2rem;
  border-radius: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  h2 {
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
  h4 {
    text-decoration: underline;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
  button {
    margin-top: 1rem;
    width: 100%;
    padding: 1rem;
    border: none;
    background-color: $primary;
    border-radius: 00.5rem;
    color: whitesmoke;
    cursor: pointer;
  }
}
